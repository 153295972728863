<template>
  <a-spin :spinning="loading">
    <textarea :id="id"></textarea>
  </a-spin>
</template>

<script>
import { 
  aliyunUploadUrl
} from '@/api'
import {
  createFileInput
} from '@/utils/util'
import {
  hasProp
} from '@/utils/prop-util'
let id = 0
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      content: this.value,
      editor: null,
      cTinyMce: null,
      checkerTimeout: null,
      isTyping: false,
      id: 'tinymc' + ++id,
      loading: false
    }
  },
  watch: {
    value: function(newValue) {
      if (this.editor) {
        if (!this.isTyping) {
          if (newValue) {
            this.editor.setContent(newValue)
            this.content = newValue
          } else {
            this.clearContent()
            this.content =  ''
          }
        } else {
          this.isTyping = false
        }
      } else {
        this.content = newValue
      }
    },
    readonly(value) {
      if (value) {
        this.editor && this.editor.setMode('readonly')
      } else {
        this.editor && this.editor.setMode('design')
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init () {
      const _this = this
      const color_map = [
        '#336699', 'Primary', // 安得生企业标准色 
        '#BFEDD2', 'Light Green',
        '#FBEEB8', 'Light Yellow',
        '#F8CAC6', 'Light Red',
        '#ECCAFA', 'Light Purple',
        '#C2E0F4', 'Light Blue',
      
        '#2DC26B', 'Green',
        '#F1C40F', 'Yellow',
        '#E03E2D', 'Red',
        '#B96AD9', 'Purple',
        '#3598DB', 'Blue',
      
        '#169179', 'Dark Turquoise',
        '#E67E23', 'Orange',
        '#BA372A', 'Dark Red',
        '#843FA1', 'Dark Purple',
        '#236FA1', 'Dark Blue',
      
        '#ECF0F1', 'Light Gray',
        '#CED4D9', 'Medium Gray',
        '#95A5A6', 'Gray',
        '#7E8C8D', 'Dark Gray',
        '#34495E', 'Navy Blue',
      
        '#000000', 'Black',
        '#ffffff', 'White'
      ]
      window.tinymce.init({
        selector: "#" + this.id,
        menubar: true,
        toolbar1: "undo redo | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image axupimgs media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough |",
        toolbar2: "indent outdent | fullscreen | wordcount | ltr rtl | pagebreak | searchreplace | hr code",
        toolbar_drawer: "sliding",
        plugins: "print link image axupimgs media table lists fullscreen quickbars paste wordcount autolink directionality pagebreak searchreplace hr code",
        language: 'zh_CN',
        paste_webkit_styles: 'all',
        font_formats: "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
        init_instance_callback: this.initEditor,
        height: 700,
        color_cols: 4,
        custom_colors: true,
        color_map: color_map,
        media_live_embeds: true,
        file_picker_types: 'image media',
        video_template_callback (data) {
          return '<video width="' + data.width + '" height="' + data.height + '"' + (data.poster ? ' poster="' + data.poster + '"' : '') + ' controls="controls">\n' + '<source src="' + data.source + '"' + (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') + ' />\n' + (data.altsource ? '<source src="' + data.altsource + '"' + (data.altsourcemime ? ' type="' + data.altsourcemime + '"' : '') + ' />\n' : '') + '</video>';
        },
        media_filter_html: false,
        media_url_resolver: function(data, resolve) {
          try {
            let videoUri = encodeURI(data.url);
            let embedHtml = `<p>
              <span
                class="mce-object mce-object-video"
                data-mce-selected="1"
                data-mce-object="video"
                data-mce-p-width="100%"
                data-mce-p-height="auto"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-src=${videoUri} >
                <video src=${data.url} width="${data.width}" height="auto" controls="controls" controlslist="nodownload">
                </video>
              </span>
            </p>
            <p style="text-align: left;"></p>`
            resolve({ html: embedHtml });
          } catch (e) {
            resolve({ html: "" });
          }
        },
        images_upload_handler (blobInfo, succFun, failFun) {
          const formData = new FormData()
          formData.append('file', blobInfo.blob())
          aliyunUploadUrl(formData)
            .then(data => {
              succFun(data.data)
            })
            .catch(e => {
              failFun(e.message)
              _this.$message.error('上传失败')
            })
        },
        file_picker_callback (callback, value, meta) {
          let filetype 
          switch(meta.filetype){
            case 'image':
              filetype = '.jpg, .jpeg, .png, .gif'
              break
            case 'media':
              filetype = '.mp4'
              break
          }
          createFileInput({ accept: filetype }, {
            fileChange: (e) => {
              const file = e.target.files[0]
              if (file) {
                const formData = new FormData()
                formData.append('file', file)
                aliyunUploadUrl(formData)
                  .then(data => {
                    callback(data.data)
                  })
                  .catch(e => {
                    _this.$message.error('上传失败')
                  })
              }
            },
            autoTrigger: true
          })
        }
      })
    },
    initEditor(editor) {
      this.editor = editor
      editor.on('input Undo Redo ExecCommand', e => {
        this.submitNewContent('change',true)
      })
      editor.on('Blur', e => {
        this.$emit('blur', this.content)
      })
      if (this.content) {
        editor.setContent(this.content)
      }
      if (this.readonly) {
        this.editor.setMode('readonly')
      } else {
        this.editor.setMode('design')
      }
      this.$emit('editorInit', editor)
    },
    submitNewContent(eventType,run) {
      this.isTyping = true
      const content = this.editor.getContent()
      if (this.typingTimer) {
        clearTimeout(this.typingTimer)
        this.typingTimer = undefined
      }
      this.typingTimer = setTimeout(() => {
        if (hasProp(this, 'value')) {
          this.$emit(eventType, content)
        } else {
          this.isTyping = false
        }
        this.typingTimer = undefined
      }, 100)
      this.content = content
    },
    clearContent(){
      this.editor.setContent('')
    }
  }
}
</script>

<style>
</style>
